import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
// import useLiveControl from "../../controlled/useLiveControl"
// import LiveControlSpinner from "./../../components/liveControlSpinner"

const FencingFinancingPage = () => {
  // const { runningChecks } = useLiveControl()
  // if (runningChecks) return <LiveControlSpinner />

  return (
    <Layout>
      <Seo
        title={"Financing for Fencing Contractors"}
        description={
          "The benefits of providing financing and how a contractor financing leader can help your fence company."
        }
      />
      <section
        className="page-hero darker-overlay-04"
        style={{
          backgroundImage:
            "url(" +
            "http://gbdevpress.enerbank.com/wp-content/uploads/2023/03/fence-0--scaled.jpg" +
            ")",
          backgroundPosition: "50% 25%",
        }}
      >
        <div className="page-hero-inner d-flex justify-content-center align-items-center">
          <div className="container text-center">
            <h1 className="text-white">
              Learn How Fencing Contractors Can Offer Financing
            </h1>
          </div>
        </div>
      </section>
      <section className="bg-light-gray py-5">
        <div className="container">
          <div>
            <p>
              When fencing contractors offer{" "}
              <Link
                to="/contractor-financing/"
                className="text-blue font-weight-bold"
              >
                contractor financing for clients
              </Link>{" "}
              , they increase their leads and close even more of those leads.
              With Regions | EnerBank USA, you can offer flexible solutions to
              fund the project. Keep reading and we&#39;ll examine the benefits
              of providing financing and show how a contractor financing leader
              can help your fence company. leader can help your fence company.
            </p>
          </div>
        </div>
      </section>

      <section className="bg-light-gray industry">
        <div className="bg-blue py-4">
          <div className="container">
            <h2 className="text-center text-white">
              Why Should Fencing Contractors Offer Financing?
            </h2>
          </div>
        </div>
        <div className="container">
          <div className="row py-5 financing-x2-images">
            <div className="col-md-6">
              <div className="card">
                <div className="card-image">
                  <img
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/03/opportunities.png"
                    alt="more opportunities"
                  />
                </div>
                <div className="card-body">
                  <h3 className="text-center">
                    More Opportunities for Larger Projects
                  </h3>
                  <p className="card-text">
                    Fencing projects are often a considerable financial
                    investment, forcing some clients to scale back their plans
                    to save money. When you offer alternatives to fund a fencing
                    job without requiring them to immediately pay out of pocket,
                    customers will be more open to expanding the scope of work,
                    meaning more profit for you.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="card">
                <div className="card-image">
                  <img
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/03/fast.png"
                    alt="faster projects rate"
                  />
                </div>
                <div className="card-body">
                  <h3>Start Projects Faster</h3>
                  <p>
                    Offering financing for fence installation projects lets you
                    close deals faster and get started with the actual fencing
                    work. You decrease the chance of lengthy client
                    negotiations, which take up more time. Clients can quickly
                    and conveniently choose a payment option and sign your
                    contract.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="card">
                <div className="card-image">
                  <img
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/03/win.png"
                    alt="competitive"
                  />
                </div>
                <div className="card-body">
                  <h3>Be More Competitive</h3>
                  <p>
                    Do fencing companies offer financing in your area? If they
                    don&#39;t, offering payment options makes your service far
                    more appealing than your competition. And if they do, you
                    can provide flexibility to set yourself apart even more.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="card">
                <div className="card-image">
                  <img
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/03/boost.png"
                    alt="great reputation"
                  />
                </div>
                <div className="card-body">
                  <h3>Boost Your Reputation</h3>
                  <p>
                    Providing a significant benefit like financing to your
                    clients improves their perception of your fencing company.
                    They can hire you again for future fencing projects because
                    you facilitated the sales process and helped them stay
                    flexible with their money. Past clients might also recommend
                    you to others needing a fence company that finances.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-light-gray">
        <div className="bg-blue py-4">
          <div className="container">
            <h2 className="text-center text-white">
              How You Can Start Offering Financing for Fencing Projects
            </h2>
          </div>
        </div>
        <div className="container">
          <div className="row py-5">
            <div className="col-lg-7 mb-5 mb-lg-0">
              <img
                src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/03/contractor-on-phone--scaled.jpg"
                alt="offering contractor financing"
              />
            </div>

            <div className="col-lg-5 pl-lg-5">
              <p>
                Fencing contractors can start by creating a{" "}
                <Link to="/sign-up/" className="text-blue font-weight-bold">
                  loan program
                </Link>{" "}
                with a financing provider like EnerBank first. We have several{" "}
                to choose from before we add the funding application button to
                your website.
              </p>
              <p className="mt-4">
                Your clients will have three funding application options:
              </p>

              <ul>
                <li className="landscape-why-enerbank-item">Mobile Loan App</li>
                <li className="landscape-why-enerbank-item">
                  Online Loan Application
                </li>
                <li className="landscape-why-enerbank-item">Loan-by-Phone</li>
              </ul>

              <p className="mt-4">
                No paperwork is needed here; we’ll approve the application
                quickly so you can start the fencing job immediately.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="benefits" className="bg-light-gray">
        <div className="bg-blue py-4">
          <div className="container">
            <h2 className="text-center text-white">
              Why Choose EnerBank for Fencing Contractor Financing?
            </h2>
          </div>
        </div>
        <div className="container py-5">
          <ul>
            <li className="mb-4 d-flex align-items-start">
              <div className="wcf-img-li-box mr-3">
                <img
                  src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/03/experience.png"
                  alt="Experience"
                />
              </div>
              <p className="ml-2">
                <span className="font-weight-bold">Experience </span> — EnerBank
                has over 20 years of experience in home contractor financing. We
                know the hurdles contractors face when trying to close a deal.
                With a trusted veteran provider on your side, homeowners will
                have more faith that the funds are in good hands.
              </p>
            </li>

            <li className="mb-4 d-flex align-items-start">
              <div className="wcf-img-li-box mr-3">
                <img
                  src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/03/payment-options.png"
                  alt="unique payment options"
                />
              </div>
              <p className="ml-2">
                <span className="font-weight-bold">
                  Tailor-Made Payment Options{" "}
                </span>{" "}
                — We developed our loan products with home improvement projects
                in mind—including fencing jobs. This focus makes it easier for
                clients to pay the installments later.
              </p>
            </li>

            <li className="mb-4 d-flex align-items-start">
              <div className="wcf-img-li-box mr-3">
                <img
                  src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/03/fast-financing.png"
                  alt="fast financing"
                />
              </div>
              <p className="ml-2">
                <span className="font-weight-bold">Fast Financing </span> — Do
                you need funds for more fencing materials or the project’s next
                phase? Request at any time, and once your client approves, we’ll
                deposit the money into your account usually within 24 hours.
              </p>
            </li>
          </ul>
          <p className="py-4">
            Are you ready to join a loan program or want to learn more about
            financing for fencing contractors?{" "}
            <Link to="/contact/" className="text-blue font-weight-bold">
              Contact us today
            </Link>
            , and an EnerBank team member will get back to you soon.
          </p>

          <p className="text-center">
            <Link to="/contact/" className="btn btn-primary font-weight-bold">
              Get in Touch Today!
            </Link>
          </p>
        </div>
      </section>
    </Layout>
  )
}

export default FencingFinancingPage
